
<div class="page-title-area service-title-area">
    <div id="particles-js" class="particles-js-page"></div>
    <div class="container">
        <div class="page-title-content">
            <h2 class="service-title">{{ memberApiData.name }} Profile</h2>
        </div>
    </div>
</div>

<section class="video-presentation-area ptb-100 bg-f4f6fc">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-4">
                <div class="single-team-box">
                    <div class="image">
                        <img src="{{ APP_PATH+memberApiData.image_path }}" alt="image">
                       
                    </div> 
                    <div class="content">
                        <h3 >{{ memberApiData.email }}</h3>
                        <span>{{ memberApiData.phone_no }}</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 col-md-8">
                <div class="card" style="border: none;">
                    <div class="card-body">
                        <span>{{ memberApiData.designation }}</span>
                        <h3 style="font-size: 22px; font-weight: 500;  color: #333333;">{{ memberApiData.name }}</h3>
                        <p>
                            <ng-container *ngFor="let skill of skills; let last = last">
                              {{ skill }}{{ !last ? ' | ' : '' }}
                            </ng-container>
                        </p>

                        <h3 class="mt-5" style="font-size: 18px; font-weight: 500;  color: #333333;">Professional Skills</h3>
                        <div class="row">
                            <div class="col-md-6" *ngFor="let exp of expert; let i = index">
                                <h6>{{ skills[i] }}</h6>
                              <div class="progress">
                                <div
                                  class="progress-bar" style="background-color: #7fe0fe;"
                                  role="progressbar"
                                  [style.width.%]="exp"
                                  [attr.aria-valuenow]="exp"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                >
                                  {{ exp }}%
                                </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
                
              
            <div class="col-lg-12 col-md-12 mt-3">
                <h3 style="font-size: 22px; font-weight: 500;  color: #333333;">Experience & Activities</h3>
                <p [innerHTML] = "sanitizeHtml(memberApiData.detail)"></p>
            </div>
        </div>
    </div>
</section>

<section class="free-trial-area ptb-100 bg-f4f5fe">
    <div class="container">
        <div class="free-trial-content">
            <h2>We always try to be the best support to you as possible</h2>
            <p>Qualify your leads & recognize the value of word your customer will love you</p>
            <a routerLink="/contact" class="default-btn"><i class="bx bxs-hot"></i> Contact Us<span></span></a>
        </div>
    </div>
    <div class="shape10"><img src="assets/img/shape/shape10.png" alt="image"></div>
    <div class="shape11"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape12"><img src="assets/img/shape/shape11.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/shape12.png" alt="image"></div>
</section>