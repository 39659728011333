import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { DomSanitizer, SafeHtml  } from '@angular/platform-browser';

@Component({
  selector: 'app-services-one',
  templateUrl: './services-one.component.html',
  styleUrls: ['./services-one.component.scss']
})
export class ServicesOneComponent implements OnInit {
  dynamicId: string;

  headerImage ='';
  detail = '';
  title = ''; 
  projectsApiData : any = [];
  whyusApiData: any = [];
  APP_PATH = "https://admin.rapidsai.com/";
  API_PATH = "https://admin.rapidsai.com/api/";
  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private top_title: Title,
    private datePipe: DatePipe,
    private sanitizer: DomSanitizer
  ) {
      this.top_title.setTitle('Services - RapidsAI');
    }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.dynamicId  = params['id']; // Get the 'id' parameter from the route
      this.get_service_detail();
    }); 
  }

  get_service_detail(){
    this.http.get(this.API_PATH + 'whyusService/' + this.dynamicId).subscribe((data) => {
      this.whyusApiData = data;
    
      if (this.whyusApiData && this.whyusApiData.length > 0) {
        for (let i = 0; i < this.whyusApiData.length; i++) {
          if (this.whyusApiData[i].type && this.whyusApiData[i].type === 'main') {
            this.headerImage = this.APP_PATH + this.whyusApiData[i].image_path;
            this.detail = this.whyusApiData[i].detail;
            this.title = this.whyusApiData[i].title;
            console.log("title:" + this.title);
          }
        }
      } else {
        console.error('No data or empty array received from the API.');
      }
    });
    
    
    this.get_projects();
  }
  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
  get_projects(){
    this.http.get(this.API_PATH+'projectsById/'+this.dynamicId).subscribe((data) => {
      this.projectsApiData = data;
    });
  }
  formatDate(dateString: string): string {
    // Parse the input date into a Date object
    const parsedDate = this.datePipe.transform(dateString, 'dd/MM/yyyy', 'MM/dd/yyyy');

    // Format the date in the desired format
    return this.datePipe.transform(parsedDate, 'MMMM dd, yyyy');
  }
}
