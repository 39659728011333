
<div class="page-title-area service-title-area">
    <div id="particles-js" class="particles-js-page"></div>
    <div class="container">
        <div class="page-title-content">
            <h2 class="service-title">{{parentTitle}} Projects</h2>
        </div>
    </div>
    
</div>

<section class="video-presentation-area ptb-100" style="position: relative;background-color:white">
<div class="container">
    <div class="row">
        <div class="col-md-12">
            <!-- <mat-tab-group >
                <mat-tab [label]="tab.key" *ngFor="let tab of categoriesApiData | keyvalue"> 
                <section class="blog-area">
                    <div class="container">
                        <!- <div class="section-title">
                            <!- <h2>{{ tab.key }}</h2> ->
                            <h2>{{parentTitle}} Projects</h2>
                        </div> ->
                            
                        <!- <div class="col-lg-4 col-md-6"   *ngFor="let item of tab.value.projects;">->
                        <div class="row"   *ngFor="let tab of categoriesApiData | keyvalue">
                            <div class="col-lg-4 col-md-6" *ngFor="let item of tab.value.projects;">
                                <div class="single-blog-post wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                    <div class="post-image">
                                        <a [routerLink]="['/project-detail',item.project_title.replace(' ', '-')+'-'+item.id]"><img src="{{ APP_PATH+item.image_path }}" alt="image"></a>
                                        <div class="date"><i class='bx bx-calendar'></i>{{ formatDate(item.created_at) }}</div>
                                    </div>
                                    <div class="post-content">
                                        <h3><a [routerLink]="['/project-detail',item.project_title.replace(' ', '-')+'-'+item.id]">{{ item.project_title }}</a></h3>
                                        <div class="post-info">
                                            <div class="post-by">
                                                <h6>{{ item.technologies }} 
                                                    <!- - {{ item.child_category}} ->
                                                </h6>
                                            </div>
                                            <div class="details-btn">
                                                <a [routerLink]="['/project-detail',item.project_title.replace(' ', '-')+'-'+item.id]"><i class="bx bx-right-arrow-alt"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            <!- </mat-tab>
        </mat-tab-group> -->
        <div class="row" >
            <div class="col-lg-4 col-md-6" *ngFor="let item of categoriesApiData">
                <div class="single-blog-post wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                    <div class="post-image">
                        <a [routerLink]="['/project-detail',item.project_title.replace(' ', '-')+'-'+item.id]"><img src="{{ APP_PATH+item.image_path }}" alt="image"></a>
                        <!-- <div class="date"><i class='bx bx-calendar'></i>{{ formatDate(item.created_at) }}</div> -->
                    </div>
                    <div class="post-content">
                        <h3 class="post-content-h3"><a [routerLink]="['/project-detail',item.project_title.replace(' ', '-')+'-'+item.id]">{{ item.project_title }}</a></h3>
                        <div class="post-info">
                            <!-- <div class="post-by">
                                <h6>{{ item.technologies }} 
                                    <!-{{ item.child_category}} ->
                                </h6>
                            </div> -->
                            <div class="details-btn">
                                <a [routerLink]="['/project-detail',item.project_title.replace(' ', '-')+'-'+item.id]"><i class="bx bx-right-arrow-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
</div>
</section>
