<style>
   .main-banner {
    position: relative; /* Make the "main-banner" div a positioned container */
}

#particles-js {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; /* Set a higher z-index to make it appear on top */
}

/* Adjust styles for your "main-banner" content */
.main-banner-content {
    /* Your existing styles for the content */
    position: relative; /* Ensure content appears over Particle.js */
    z-index: 2; /* Set a z-index higher than the Particle.js container */
}

</style>
<div class="main-banner main-banner-one">
    <div id="particles-js"></div> <!-- Move this inside the "main-banner" div -->
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="main-banner-content">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="content">
                                <h1>test</h1>
                                <p>test</p>
                                <!-- <a routerLink="/contact" class="default-btn">
                                    <i class="bx bxs-hot"></i>Get Started<span></span>
                                </a> -->
                            </div>
                        </div>
                    </div>
                </div>            </div>
            <div class="col-lg-5 col-md-12">
                <div class="banner-image mbanner-bg-one">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="animate-banner-image">
                                <img src="https://admin.rapidsai.com/uploads//652e224935bca.jpg" alt="image">
                            </div>
                        </div>
                    </div>
                    <img src="https://admin.rapidsai.com/uploads//652e224935bca.jpg" class="mbanner-img" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>
