
<div class="page-title-area service-title-area">
    <div id="particles-js" class="particles-js-page"></div>
    <div class="container">
        <div class="page-title-content">
            <h2 class="service-title">Jobs</h2>
        </div>
    </div>
</div>
<section class="video-presentation-area  pt-100 bg-f4f6fc">
    <div class="container">
        <div class="section-title"> 
            <h2 class="mb-2">More to Discover</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
    </div>
    <div class="main" *ngFor="let job of jobsApiData; let i = index ">
        <div class="overview-item wow animate__animated animate__fadeInUp pt-5 pb-5" data-wow-delay=".2s" *ngIf="i % 2 === 0">
            <div class="container max-width-1290">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6">
                        <div class="overview-left-img">
                            <img src="{{ APP_PATH+job.image_path }}" alt="image">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="overview-content pl-3">
                            <span class="number">0{{i+1}}</span>
                            <h3>{{job.post_title}}</h3>
                            <p [innerHTML] = "sanitizeHtml(job.job_text)"></p>
                            <div class="row mt-3">
                                 <div class="col-lg-6 col-md-6 col-sm-6" *ngIf="job.qualification">
                                    <div class="feature-box">
                                        <i class='bx bxs-badge-check'></i> Qualification: {{ job.qualification}}
                                    </div>
                                </div>
                                 <div class="col-lg-6 col-md-6 col-sm-6" *ngIf="job.skills">
                                    <div class="feature-box">
                                        <i class='bx bxs-badge-check'></i> Skills: {{ job.skills}}
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6" *ngIf="job.vacancies">
                                    <div class="feature-box">
                                        <i class='bx bxs-badge-check'></i>  No. of Positions: {{ job.vacancies}}
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6" *ngIf="job.location">
                                    <div class="feature-box">
                                        <i class='bx bxs-badge-check'></i>  Location: {{ job.location}}
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6" *ngIf="job.experience">
                                    <div class="feature-box">
                                        <i class='bx bxs-badge-check'></i>  Experience: {{ job.experience}}
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6"  *ngIf="job.salary">
                                    <div class="feature-box">
                                        <i class='bx bxs-badge-check'></i>  Salary: {{ job.salary}}
                                    </div>
                                </div>
                            </div>
                            <a [routerLink]="['/job-detail',transformJobTitle(job.post_title)+'-'+job.id]"  class="default-btn black-btn"><i class='bx bxs-arrow-to-right'></i>Read More <span></span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="overview-item wow animate__animated animate__fadeInUp pt-5 pb-5" data-wow-delay=".4s" *ngIf="i % 2 === 1" style="background-color: white;">
            <div class="container max-width-1290">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6">
                        <div class="overview-content pl-3">
                            <span class="number">0{{i+1}}</span>
                            <h3>{{job.post_title}}</h3>
                            <p [innerHTML] = "sanitizeHtml(job.job_text)"></p>
                            <div class="row mt-3">
                                <div class="col-lg-6 col-md-6 col-sm-6" *ngIf="job.qualification">
                                   <div class="feature-box">
                                       <i class='bx bxs-badge-check'></i> Qualification: {{ job.qualification}}
                                   </div>
                               </div>
                                <div class="col-lg-6 col-md-6 col-sm-6" *ngIf="job.skills">
                                   <div class="feature-box">
                                       <i class='bx bxs-badge-check'></i> Skills: {{ job.skills}}
                                   </div>
                               </div>
                               <div class="col-lg-6 col-md-6 col-sm-6" *ngIf="job.vacancies">
                                   <div class="feature-box">
                                       <i class='bx bxs-badge-check'></i>  No. of Positions: {{ job.vacancies}}
                                   </div>
                               </div>
                               <div class="col-lg-6 col-md-6 col-sm-6" *ngIf="job.location">
                                   <div class="feature-box">
                                       <i class='bx bxs-badge-check'></i>  Location: {{ job.location}}
                                   </div>
                               </div>
                               <div class="col-lg-6 col-md-6 col-sm-6" *ngIf="job.experience">
                                   <div class="feature-box">
                                       <i class='bx bxs-badge-check'></i>  Experience: {{ job.experience}}
                                   </div>
                               </div>
                               <div class="col-lg-6 col-md-6 col-sm-6"  *ngIf="job.salary">
                                   <div class="feature-box">
                                       <i class='bx bxs-badge-check'></i>  Salary: {{ job.salary}}
                                   </div>
                               </div> 
                           </div>
                            <a [routerLink]="['/job-detail',transformJobTitle(job.post_title)+'-'+job.id]"  class="default-btn black-btn"><i class='bx bxs-arrow-to-right'></i>Read More <span></span></a>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="overview-right-img">
                            <img src="{{ APP_PATH+job.image_path }}" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>