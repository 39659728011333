<div class="page-title-area service-title-area">
    <div id="particles-js" class="particles-js-page"></div>
    <div class="container">
        <div class="page-title-content">
            <h2>{{ jobApiData.post_title }} Detail</h2>
        </div>
    </div>
</div>
<section class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-image">
                        <img src="{{ APP_PATH+jobApiData.image_path }}" alt="image">
                    </div>
                    <div class="article-content">
                        <div class="entry-meta">
                            <ul>
                                <li><i class='bx bx-time'></i> <a href="#">{{ (jobApiData.start_date) }}</a></li>
                                <li><i class='bx bx-time'></i> <a href="#">{{ (jobApiData.last_date) }}</a></li>
                            </ul>
                        </div>
                        <div [innerHTML] = "jobApiData.job_text"></div>
                        <div class="row mt-3">
                            <div class="col-lg-6 col-md-6 col-sm-6" *ngIf="jobApiData.qualification">
                               <div class="feature-box">
                                   <i class='bx bxs-badge-check'></i> Qualification: {{ jobApiData.qualification}}
                               </div>
                           </div>
                            <div class="col-lg-6 col-md-6 col-sm-6" *ngIf="jobApiData.skills">
                               <div class="feature-box">
                                   <i class='bx bxs-badge-check'></i> Skills: {{ jobApiData.skills}}
                               </div>
                           </div>
                           <div class="col-lg-6 col-md-6 col-sm-6" *ngIf="jobApiData.vacancies">
                               <div class="feature-box">
                                   <i class='bx bxs-badge-check'></i>  No. of Positions: {{ jobApiData.vacancies}}
                               </div>
                           </div>
                           <div class="col-lg-6 col-md-6 col-sm-6" *ngIf="jobApiData.location">
                               <div class="feature-box">
                                   <i class='bx bxs-badge-check'></i>  Location: {{ jobApiData.location}}
                               </div>
                           </div>
                           <div class="col-lg-6 col-md-6 col-sm-6" *ngIf="jobApiData.experience">
                               <div class="feature-box">
                                   <i class='bx bxs-badge-check'></i>  Experience: {{ jobApiData.experience}}
                               </div>
                           </div>
                           <div class="col-lg-6 col-md-6 col-sm-6"  *ngIf="jobApiData.salary">
                               <div class="feature-box">
                                   <i class='bx bxs-badge-check'></i>  Salary: {{ jobApiData.salary}}
                               </div>
                           </div> 
                       </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="free-trial-area ptb-100 bg-f4f5fe">
    <div class="container">
        <div class="free-trial-content">
            <h2>We always try to be the best support to you as possible</h2>
            <p>Qualify your leads & recognize the value of word your customer will love you</p>
            <a routerLink="/contact" class="default-btn"><i class="bx bxs-hot"></i> Contact Us<span></span></a>
        </div>
    </div>
</section>