<div class="page-title-area service-title-area">
    <div id="particles-js" class="particles-js-page"></div>
    <div class="container">
        <div class="page-title-content">
            <h2 class="service-title">About us</h2>
        </div>
    </div>
</div>
<section class="video-presentation-area  ptb-100 bg-fff">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">About Us.</span>
                    <h2 [innerHTML]="title"></h2>
                    <p [innerHTML]="detail"></p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="announcement-slides owl-carousel owl-theme" >
                    <div class="about-image"  *ngFor="let item of aboutImages;">
                        <img src="{{ APP_PATH+item.image_path }}" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="video-presentation-area  pt-100 pb-70 bg-f8fbfa" >
    <div class="container">
        <div class="section-title">
            <h2>Mission and Vision</h2>
        </div>
        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6 wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                <div class="single-features-card tx-center">
                    <i class='bx bx-conversation'></i>
                    <h3><a routerLink="/services-details">Vision</a></h3>
                    <p> Our company’s vision encompasses the advancement of global development on a significant scale.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6 wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                <div class="single-features-card tx-center">
                    <i class='bx bx-laptop'></i>
                    <h3><a routerLink="/services-details">Mission</a></h3>
                    <p>  We collaborate with clients to develop software solutions that contribute to making the world a better place.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6 wow animate__animated animate__fadeInUp" data-wow-delay=".6s">
                <div class="single-features-card tx-center">
                    <i class='bx bxs-badge-check'></i>
                    <h3><a routerLink="/services-details">Values</a></h3>
                    <p> We excel at what we love, taking pride in our evolving team while achieving results through flexible and open collaboration.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="video-presentation-area  pt-100 pb-70 bg-f8fbfa">
    <div class="container">
        <div class="section-title">
            <h2>Challenges we help to solve</h2>
        </div>
        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6 wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                <div class="single-features-card tx-center">
                    <i class='bx bx-conversation'></i>
                    <h3><a routerLink="/services-details"> IT expertise gap</a></h3>
                    <p>We bridge expertise and technological gaps for our customers by offering experienced teams of IT professionals to successfully complete software development projects of any complexity.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6 wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                <div class="single-features-card tx-center">
                    <i class='bx bx-laptop'></i>
                    <h3><a routerLink="/services-details">Cost reduction
                    </a></h3>
                    <p>Rapidsai Group offers comprehensive global IT outsourcing, optimizing software development costs worldwide. With expertise in turnkey projects, we deliver the benefits of outsourcing.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6 wow animate__animated animate__fadeInUp" data-wow-delay=".6s">
                <div class="single-features-card tx-center">
                    <i class='bx bxs-badge-check'></i>
                    <h3><a routerLink="/services-details">Digital transformation
                    </a></h3>
                    <p>Rapidsai Group: Your trusted partner for comprehensive digital transformation solutions, enabling businesses to unlock their full growth potential with unwavering commitment and expertise.
                    </p>
                </div>
            </div>
         
        </div>
    </div>
</div>

<section class="video-presentation-area  pt-100 pb-70 bg-fff" *ngIf="topThreeTeamApiData.length > 0 ">
    <div class="container">
        <div class="section-title">
            <h2>Board of Directors</h2>
        </div>
        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6" *ngFor="let item of topThreeTeamApiData;">
                <div class="single-team-box wow animate__animated animate__fadeInUp" data-wow-delay=".6s">
                    <div class="image">
                        <a [routerLink]="['/team-detail',transformJobTitle(item.name)+'-'+item.id]">
                            <img src="{{ APP_PATH+item.image_path }}" alt="image">
                        </a>
                        <ul class="social">
                            <li><a href="{{ item.facebook_link}}" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="{{ item.twitter_link}}" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="{{ item.linkedin_link}}" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="{{ item.instagram_link}}" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <a [routerLink]="['/team-detail',transformJobTitle(item.name)+'-'+item.id]">
                            <h3>{{ item.name }}</h3>
                        </a>
                        <span>{{ item.designation }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="testimonials-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Whats Our Clients Said About <span>RAPIDS AI</span></h2>
        </div>
        <div class="testimonials-slides owl-carousel owl-theme">
            <div class="single-testimonials-item" *ngFor="let item of testimonialsApiData">
                <div class="client-info">
                    <img src="{{ APP_PATH+item.image_path }}" alt="image">
                    <h3>{{item.client_name}}</h3>
                    <span>{{item.company_name}}</span>
                </div>
                <div class="testimonials-desc">
                    <p>{{item.testimonial_text}}</p>
                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>
                </div>
            </div>
           
        </div>
    </div>
</section>
<section class="video-presentation-area  ptb-100 bg-f4f5fe"   *ngIf="clientsApiData.length > 0">
    <div class="container">
        <div class="section-title">
            <h2>Our Loving Clients</h2>
        </div>
        <div class="clients-logo-list align-items-center">
            <div class="single-clients-logo wow animate__animated animate__fadeInUp" data-wow-delay=".2s"  *ngFor="let item of clientsApiData">
                <a href="#">
                    <img src="{{ APP_PATH+item.image_path }}" alt="image">
                </a>
            </div>
        </div>
    </div>
</section>