import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-job-detail',
  templateUrl: './job-detail.component.html',
  styleUrls: ['./job-detail.component.scss']
})
export class JobDetailComponent {
  APP_PATH = "https://admin.rapidsai.com/";
  API_PATH = "https://admin.rapidsai.com/api/";  
  dynamicId : string;
  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private top_title: Title,
    private datePipe: DatePipe
  ) {
    this.top_title.setTitle('Jobs - RapidsAI');
  }
  jobTitle = '';
  jobApiData : any = [];
  ngOnInit() {
    this.route.params.subscribe(params => {
      this.dynamicId  = params['id']; // Get the 'id' parameter from the route
      const parts = this.dynamicId.split('-');
      this.jobTitle = parts[0];

      this.get_job_detail();
    }); 
  }
  get_job_detail(){
    this.http.get(this.API_PATH+'job_detail/'+this.dynamicId).subscribe((data) => {
      this.jobApiData = data;
    });
  }
  formatDate(dateString: string): string {
    // Parse the input date into a Date object
    const parsedDate = this.datePipe.transform(dateString, 'dd/MM/yyyy', 'MM/dd/yyyy');

    // Format the date in the desired format
    return this.datePipe.transform(parsedDate, 'MMMM dd, yyyy');
  }
}
