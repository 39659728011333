import { Component } from '@angular/core';

@Component({
  selector: 'app-team-one',
  templateUrl: './team-one.component.html',
  styleUrls: ['./team-one.component.scss']
})
export class TeamOneComponent {

}
