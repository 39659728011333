<style>
    /* .main-banner {
        position: relative;
    } */
    
    /* .main-banner-content {
        position: relative;
        z-index: 2; 
    } */
 </style>
 <div id="particles-js" class="home-particles-js"></div> 
 <div class="main-banner main-banner-one bg-fff" style="position: relative;">
     <!-- Move this inside the "main-banner" div  -->
     <div class="container-fluid ">
         <div class="row">
             <div class="col-lg-7 col-md-12">
                 <div class="main-banner-content">
                     <div class="d-table">
                         <div class="d-table-cell">
                             <div class="content">
                                <div [innerHTML] = "headerTitle"></div>
                                <p [innerHTML] = "headerApiData.detail"></p>
                             </div>
                         </div>
                     </div>
                 </div>            
            </div>
            <div class="col-lg-5 col-md-12">
                 <div class="banner-image mbanner-bg-one">
                     <div class="d-table">
                         <div class="d-table-cell">
                             <div class="animate-banner-image"> 
                                <img src="{{headerImage }}" alt="image" id="hover_image">
                             </div>
                         </div>
                     </div>
                </div>
             </div>
         </div>
     </div>
 </div> 
<section class="announcement-area">
    <div class="container">
        <div class="row">
            <div class="col-md-1" style="text-align: center;">
                <img src="assets/img/an.png">
            </div>
            <div class="col-md-11">
                <div class="announcement-slides owl-carousel owl-theme">
                    <div class="single-announcement-item"  *ngFor="let item of annoucementsApiData;"> 
                        <h2 [innerHTML]="sanitizeHtml(item.detail)"></h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="video-presentation-area ptb-100 bg-fff">
    <div class="container">
        <div class="section-title" *ngIf="why_choose_us_link">
            <h2 class="section-title-h2">Exploring the Extraordinary with  Rapids AI <br> 
            <span class="section-title-span"> A New Era of Innovation</span></h2>
        </div>
        <div class="video-box" *ngIf="why_choose_us_link">
            <video  autoplay controls [muted]="true" class="w-100">
                <source src="assets/IMG_8171.mp4" type="video/mp4">
                Your browser does not support the video tag.
            </video>
        </div>
    </div>
</section>

<div  *ngFor="let item of serviceDetailApiData; let i = index">
    <section class="services-area bg-right-shape ptb-100" *ngIf="i == 0 || i == 2 || i ==4 || i == 6">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="services-content it-service-content">
                    <div class="content left-content">
                        <div class="icon feature-box" style="width: 49px; height: 50px; padding: 10px;">
                            <img src="assets/img/fav.png" alt="image" width="40">
                        </div>
                        <h2>{{item.title}}</h2>
                        <div [innerHTML]="sanitizeHtml(item.detail)"></div> 
                        <a [routerLink]="item.learn_more_link" class="default-btn"><i class="bx bxs-spreadsheet"></i> Learn More<span></span></a>
                    </div>
                </div>
                <div class="services-image wow animate__animated animate__fadeInRight" data-wow-delay=".3s">
                    <div class="image">
                        <img src="{{ APP_PATH+item.image_path }}" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="services-area bg-left-color bg-left-shape bg-f4f6fc ptb-100"  *ngIf="i == 1 || i == 3 || i == 5 || i == 7">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="services-image wow animate__animated animate__fadeInLeft" data-wow-delay=".3s">
                    <div class="image">
                        <img src="{{ APP_PATH+item.image_path }}" alt="image">
                    </div>
                </div>
                <div class="services-content it-service-content">
                    <div class="content">
                        <div class="icon feature-box" style="width: 49px; height: 50px; padding: 10px;">
                            <img src="assets/img/fav.png" alt="image" width="40">
                        </div>
                        <h2>{{item.title}}</h2>
                        <div [innerHTML]="sanitizeHtml(item.detail)"></div>  
                        <a [routerLink]="item.learn_more_link"  class="default-btn"><i class="bx bxs-spreadsheet"></i>Learn More<span></span></a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<section class="video-presentation-area pt-100 bg-fff"   *ngIf="projectsApiData.length > 0">
    <div class="container">
        <div class="section-title">
            <h2>Our Latest Projects</h2>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6"   *ngFor="let item of projectsApiData;">
                <div class="single-blog-post wow animate__animated animate__fadeInUp" data-wow-delay=".2s" style="border-radius: 30px; ">
                    <div class="post-image">
                        <a [routerLink]="['/project-detail',item.project_title.replace(' ', '-')+'-'+item.id]"><img src="{{ APP_PATH+item.image_path }}" alt="image"></a>
                        <!-- <div class="date"><i class='bx bx-calendar'></i>{{ formatDate_slash(item.created_at) }}</div> -->
                    </div>
                    <div class="post-content" style="padding-top: 0px">
                        <h3 class="post-content-h3"><a [routerLink]="['/project-detail',item.project_title.replace(' ', '-')+'-'+item.id]">{{ item.project_title }}</a></h3>
                        <div class="post-info">
                            <div class="post-by">
                                <!-- <h6>{{ item.technologies }}</h6> -->
                            </div>
                            <div class="details-btn">
                                <a [routerLink]="['/project-detail',item.project_title.replace(' ', '-')+'-'+item.id]"><i class="bx bx-right-arrow-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
<!-- </section>
<section class="video-presentation-area bg-fff"> -->

    <div class="container">
        <div class="funfacts-inner">
            <div class="row">
                <div class="col-lg-3 col-6 col-sm-6">
                    <div class="single-funfacts funfact-style-two wow animate__animated animate__fadeInLeft" data-wow-delay=".1s">
                        <i class='bx bx-list-check'></i>
                        <h3><span class="odometer" [attr.data-count]="completed_projects">00</span><span class="sign-icon">+</span></h3>
                        <p>Completed Projects</p>
                    </div>
                </div>
                <div class="col-lg-3 col-6 col-sm-6">
                    <div class="single-funfacts funfact-style-two wow animate__animated animate__fadeInLeft" data-wow-delay=".2s">
                        <i class='bx bx-smile'></i>
                        <h3><span class="odometer" [attr.data-count]="happy_client">00</span><span class="sign-icon">+</span></h3>
                        <p>Happy Clients</p>
                    </div>
                </div>
                <div class="col-lg-3 col-6 col-sm-6">
                    <div class="single-funfacts funfact-style-two wow animate__animated animate__fadeInLeft" data-wow-delay=".3s">
                        <i class='bx bx-grid-small'></i>
                        <h3><span class="odometer" [attr.data-count]="ongoing_projects">00</span><span class="sign-icon">+</span></h3>
                        <p>Ongoing Projects</p>
                    </div>
                </div>
                <div class="col-lg-3 col-6 col-sm-6 wow animate__animated animate__fadeInLeft" data-wow-delay=".4s">
                    <div class="single-funfacts funfact-style-two">
                        <i class='bx bxs-award'></i>
                        <h3><span class="odometer" [attr.data-count]="winning_award">00</span><span class="sign-icon">+</span></h3>
                        <p>Winning Awards</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="video-presentation-area ptb-100 bg-f4f5fe" *ngIf="servicesApiData.length > 0">
    <div class="container">
        <div class="section-title">
            <h2>Our amazing features</h2>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let item of servicesApiData; let i = index">
                <div class="features-box-one wow animate__animated animate__fadeInLeft" data-wow-delay=".2s">
                    <div [innerHTML]="item.image_path"></div>      
                    <h3>{{ item.title }}</h3>
                    <p>{{ item.detail }}</p>
                </div>
            </div>
           
        </div>
    </div>
</section> -->

<section class="testimonials-area pt-100 pb-70" >
    <div class="container">
        <div class="section-title">
            <h2>Whats Our Clients Said About <span>RAPIDS AI</span></h2>
        </div>
        <div class="testimonials-slides owl-carousel owl-theme">
            <div class="single-testimonials-item" *ngFor="let item of testimonialsApiData">
                <div class="client-info">
                    <img src="{{ APP_PATH+item.image_path }}" alt="image">
                    <h3>{{item.client_name}}</h3>
                    <span>{{item.company_name}}</span>
                </div>
                <div class="testimonials-desc">
                    <p>{{item.testimonial_text}}</p>
                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>
                </div>
            </div>
           
        </div>
    </div>
</section>
<section class="video-presentation-area ptb-100 bg-fff" *ngIf="faqsApiData.length > 0">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="faq-accordion">
                    <h2>Get to know about <span>RAPIDS AI</span></h2>
                    <div class="accordion" id="faqAccordion">
                        <div class="accordion-item"  *ngFor="let item of faqsApiData; let isFirst = first">
                            <div class="accordion-header">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    {{ item.title }}? <i class="bx bx-plus"></i>
                                </button>
                            </div>
                            <div id="collapseOne" class="accordion-collapse collapse " data-bs-parent="#faqAccordion" [ngClass]="{'show': isFirst}">
                                <div class="accordion-body">
                                    {{ item.detail }}
                                </div>
                            </div>
                        </div>
                      
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="faq-image wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                    <img src="{{ APP_PATH+faq_image.image_path }}" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<div class="container">
	<div class="row">
	 <div id="Smallchat">
<div [style.display]="isChatOpen ? 'block' : 'none'"  class="Layout Layout-open Layout-expand Layout-right" style="background-color: #3F51B5;color: rgb(255, 255, 255);opacity: 5;border-radius: 10px;">
    <div class="Messenger_messenger">
      <div class="Messenger_header" style="background-color: #333333; color: rgb(255, 255, 255);">
        <h4 class="Messenger_prompt">How can we help you?</h4> 
        <span class="chat_close_icon" (click)="toggleChat()"><i class='bx bx-window-close' ></i></span> 
    
    </div>
      <div class="Messenger_content">
        <div class="Messages">
          <div class="Messages_list">
            <div class="d-flex flex-row justify-content-end mb-4">
                <div class="p-3 me-3 border" style="border-radius: 15px; background-color: #fbfbfb;">
                  <p class="small mb-0">Thank you, I really like your product.</p>
                </div>
                <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava2-bg.webp"
                  alt="avatar 1" style="width: 45px; height: 100%;">
              </div>
              <div class="d-flex flex-row justify-content-start mb-4">
                <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"
                  alt="avatar 1" style="width: 45px; height: 100%;">
                <div class="ms-3" style="border-radius: 15px;">
                  <div class="bg-image">
                    <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/screenshot1.webp"
                      style="border-radius: 15px;" alt="video">
                    <a href="#!">
                      <div class="mask"></div>
                    </a>
                  </div>
                </div>
              </div>
  
                <div class="d-flex flex-row justify-content-start mb-4">
                    <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"
                    alt="avatar 1" style="width: 45px; height: 100%;">
                    <div class="p-3 ms-3" style="border-radius: 15px; background-color: rgba(57, 192, 237,.2);">
                    <p class="small mb-0">randon mesasge.</p>
                    </div>
                </div>
          </div>
        </div>
        <div class="Input Input-blank">
          <textarea class="Input_field" placeholder="Send a message..." style="height: 20px;"></textarea>
          <button class="Input_button Input_button-emoji">
            <div class="Icon" style="width: 18px; height: 18px;">
              <svg width="56px" height="56px" viewBox="1332 47 56 56" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="width: 18px; height: 18px;">
                <g id="emoji" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" transform="translate(1332.000000, 47.000000)">
                  <path d="M28,56 C12.536027,56 0,43.463973 0,28 C0,12.536027 12.536027,0 28,0 C43.463973,0 56,12.536027 56,28 C56,43.463973 43.463973,56 28,56 Z M28,50 C40.1502645,50 50,40.1502645 50,28 C50,15.8497355 40.1502645,6 28,6 C15.8497355,6 6,15.8497355 6,28 C6,40.1502645 15.8497355,50 28,50 Z" id="Oval-8" fill="#96AAB4" fill-rule="nonzero"></path>
                  <path d="M28,47 C18.0588745,47 10,38.9411255 10,29 C10,27.5224898 11.5469487,26.5550499 12.8754068,27.2017612 C13.0116063,27.2662365 13.0926181,27.3037345 13.1866998,27.3464814 C13.4611235,27.4711684 13.7819537,27.6111958 14.1451774,27.7627577 C15.1908595,28.199088 16.3591406,28.6365764 17.6173846,29.0449298 C21.1841638,30.2025005 24.7379224,30.8945075 28,30.8945075 C31.2620776,30.8945075 34.8158362,30.2025005 38.3826154,29.0449298 C39.6408594,28.6365764 40.8091405,28.199088 41.8548226,27.7627577 C42.2180463,27.6111958 42.5388765,27.4711684 42.8133002,27.3464814 C42.9073819,27.3037345 42.9883937,27.2662365 43.0558366,27.2344634 C44.4530513,26.5550499 46,27.5224898 46,29 C46,38.9411255 37.9411255,47 28,47 Z M28,43 C34.6510529,43 40.2188483,38.3620234 41.6456177,32.1438387 C40.9980758,32.3847069 40.320642,32.6213409 39.6173846,32.8495777 C35.6841638,34.1260741 31.7379224,34.8945075 28,34.8945075 C24.2620776,34.8945075 20.3158362,34.1260741 16.3826154,32.8495777 C15.679358,32.6213409 15.0019242,32.3847069 14.3543823,32.1438387 C15.7811517,38.3620234 21.3489471,43 28,43 Z" id="Oval-8" fill="#96AAB4" fill-rule="nonzero"></path>
                  <path d="M19,15 L19,20 C19,21.1045695 19.8954305,22 21,22 C22.1045695,22 23,21.1045695 23,20 L23,15 C23,13.8954305 22.1045695,13 21,13 C19.8954305,13 19,13.8954305 19,15 Z" id="Line" fill="#96AAB4" fill-rule="nonzero"></path>
                  <path d="M32,15 L32,20 C32,21.1045695 32.8954305,22 34,22 C35.1045695,22 36,21.1045695 36,20 L36,15 C36,13.8954305 35.1045695,13 34,13 C32.8954305,13 32,13.8954305 32,15 Z" id="Line-Copy-2" fill="#96AAB4" fill-rule="nonzero"></path>
                </g>
              </svg>
            </div>
          </button>
          <button class="Input_button Input_button-send">
            <div class="Icon" style="width: 18px; height: 18px;">
              <svg width="57px" height="54px" viewBox="1496 193 57 54" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="width: 18px; height: 18px;">
                <g id="Group-9-Copy-3" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" transform="translate(1523.000000, 220.000000) rotate(-270.000000) translate(-1523.000000, -220.000000) translate(1499.000000, 193.000000)">
                  <path d="M5.42994667,44.5306122 L16.5955554,44.5306122 L21.049938,20.423658 C21.6518463,17.1661523 26.3121212,17.1441362 26.9447801,20.3958097 L31.6405465,44.5306122 L42.5313185,44.5306122 L23.9806326,7.0871633 L5.42994667,44.5306122 Z M22.0420732,48.0757124 C21.779222,49.4982538 20.5386331,50.5306122 19.0920112,50.5306122 L1.59009899,50.5306122 C-1.20169244,50.5306122 -2.87079654,47.7697069 -1.64625638,45.2980459 L20.8461928,-0.101616237 C22.1967178,-2.8275701 25.7710778,-2.81438868 27.1150723,-0.101616237 L49.6075215,45.2980459 C50.8414042,47.7885641 49.1422456,50.5306122 46.3613062,50.5306122 L29.1679835,50.5306122 C27.7320366,50.5306122 26.4974445,49.5130766 26.2232033,48.1035608 L24.0760553,37.0678766 L22.0420732,48.0757124 Z" id="sendicon" fill="#96AAB4" fill-rule="nonzero"></path>
                </g>
              </svg>
            </div>
          </button>
        </div>
      </div>
    </div>
</div>
</div>
</div>
</div>

<div class="chat_on" (click)="toggleChat()"> <span class="chat_on_icon"><i class='bx bx-message-square-dots'></i></span> </div>











