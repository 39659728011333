import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { DomSanitizer, SafeHtml  } from '@angular/platform-browser';


@Component({
  selector: 'app-team-detail',
  templateUrl: './team-detail.component.html',
  styleUrls: ['./team-detail.component.scss']
})
export class TeamDetailComponent {
  APP_PATH = "https://admin.rapidsai.com/";
  API_PATH = "https://admin.rapidsai.com/api/"; 
  dynamicId : string;
  memberApiData : any = [];
  skills = [];
  expert = [];
  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private top_title: Title,
    private datePipe: DatePipe,
    private sanitizer: DomSanitizer
  ) {
    this.top_title.setTitle('Team - RapidsAI');
  }
  ngOnInit() {
    this.route.params.subscribe(params => {
      this.dynamicId  = params['id']; // Get the 'id' parameter from the route
      const parts = this.dynamicId.split('-');
      this.get_member_detail();
    }); 
  }
  get_member_detail(){
    this.http.get(this.API_PATH+'member_detail/'+this.dynamicId).subscribe((data) => {
      this.memberApiData = data;
      this.skills = JSON.parse(this.memberApiData.skill);
      this.expert = JSON.parse(this.memberApiData.expert);

    });
  }
  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
  formatDate(dateString: string): string {
    // Parse the input date into a Date object
    const parsedDate = this.datePipe.transform(dateString, 'dd/MM/yyyy', 'MM/dd/yyyy');

    // Format the date in the desired format
    return this.datePipe.transform(parsedDate, 'MMMM dd, yyyy');
  }
}
