import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import {MatTabsModule} from '@angular/material/tabs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule , ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/layouts/navbar/navbar.component';
import { PreloaderComponent } from './components/layouts/preloader/preloader.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { ServicesOneComponent } from './components/pages/services-one/services-one.component';
import { ProjectsComponent } from './components/pages/projects/projects.component';
import { ProjectDetailComponent } from './components/pages/project-detail/project-detail.component';
import { JobsComponent } from './components/pages/jobs/jobs.component';
import { JobDetailComponent } from './components/pages/job-detail/job-detail.component';
import { TeamDetailComponent } from './components/pages/team-detail/team-detail.component';
import { TeamOneComponent } from './pages/team-one/team-one.component';
import { TeamComponent } from './components/pages/team/team.component';



@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    PreloaderComponent,
    HomeOneComponent,
     FooterComponent,
    AboutComponent,
    ContactComponent,
    ErrorComponent,
    ServicesOneComponent,
    ProjectsComponent,
    ProjectDetailComponent,
    JobsComponent,
    JobDetailComponent,
    TeamDetailComponent,
    TeamOneComponent,
    TeamComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatTabsModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule
    ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
