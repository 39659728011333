import { Component, ElementRef, Renderer2 , OnInit} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class NavbarComponent implements OnInit {
    isNavbarVisible = false;

  
    APP_PATH = "https://admin.rapidsai.com/";
    API_PATH = "https://admin.rapidsai.com/api/";

    location: any;
    navbarClass: any = 'navbar-area';
    categoriesApiData : any = {};
    logo : any = '/assets/img/blk-logo.png';
    constructor(
        private http: HttpClient,
        private router: Router,
        location: Location,
        private renderer: Renderer2

    ) {
        this.router.events
        .subscribe((event) => {
            if ( event instanceof NavigationEnd ) {
                this.location = this.router.url;
                if (this.location == '/' || this.location == '/home-2' || this.location == '/home-3' || this.location == '/home-5' || this.location == '/home-6' || this.location == '/home-7' || this.location == '/without-animation-home-1' || this.location == '/without-animation-home-2' || this.location == '/without-animation-home-3' || this.location == '/without-animation-home-5' || this.location == '/without-animation-home-6' || this.location == '/without-animation-home-7'){
                    this.navbarClass = 'navbar-area';
                } else if (this.location == '/home-4' || this.location == '/without-animation-home-4'){
                    this.navbarClass = 'navbar-area bg-white';
                } else {
                    this.navbarClass = 'navbar-area navbar-style-two';
                }
            }
        });
    }
    toggleNavbar() {
        this.isNavbarVisible = !this.isNavbarVisible;
      }
    
      handleNavLinkClick() {
        // Close the navbar when a link inside the collapsed navbar is clicked
        this.isNavbarVisible = false;
      }
    handleDropdownItemClick() {
        console.log('Clicked');
        const dropdownMenu = document.querySelector('.dropdown-menu');
        if (dropdownMenu) {
          this.renderer.addClass(dropdownMenu, 'd-none');
        }
      }
    
      handleServicesMouseOver() {
        const dropdownMenu = document.querySelector('.dropdown-menu');
        if (dropdownMenu) {
          this.renderer.removeClass(dropdownMenu, 'd-none');
        }
      }
    
      handleServicesMouseOut() {
        const dropdownMenu = document.querySelector('.dropdown-menu');
        if (dropdownMenu) {
          this.renderer.addClass(dropdownMenu, 'd-none');
        }
      }
    ngOnInit() {
        this.http.get(this.API_PATH+'categories').subscribe((data) => {
            this.categoriesApiData = data;
        });

        this.http.get(this.API_PATH+'top_logo').subscribe((data) => {
            //this.logo = this.APP_PATH+'uploads/'+data;
        });
    }
    getCategoriesArray() {
        return this.categoriesApiData ? Object.keys(this.categoriesApiData) : [];
      }
}