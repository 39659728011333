<!-- <style>
#particles-js {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; /* Set a higher z-index to make it appear on top */
 }
</style>
<div id="particles-js"></div> -->

<footer class="footer-area">
    <div class="divider"></div>
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/"><img [src]="logo" alt="image" style="width: 150px;"></a>
                    </div>
                    <p>{{ aboutus }}</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Contact Info</h3>
                    <ul class="footer-contact-info">
                        <li><i class="own_display_top" class='bx bx-mail-send'></i> &nbsp; <a [href]="'mailto:' +email">{{ email }}</a></li>
                        <li><i class="own_display_top" class='bx bx-phone'></i>&nbsp; <a [href]="'tel:' +phone">{{ phone }}</a></li>
                        <li><i class="own_display_top" class='bx bx-current-location'></i> &nbsp; <a href="#" target="_blank">{{ address }}</a></li>
                    </ul>
                    <ul class="social">
                        <li><a class="own_display_top" href="{{ fb }}" target="_blank"><img src="assets/img/facebook.png" alt="Facebook"></a></li>
                        <li><a class="own_display_top" href="{{ twitter }}" target="_blank"><img src="assets/img/twitter.png" alt="twitter"></a></li>
                        <li><a class="own_display_top" href="{{ google }}" target="_blank"><img src="assets/img/linkedin.png" alt="linkedin"></a></li>
                        <li><a class="own_display_top" href="{{ inst }}" target="_blank"><img src="assets/img/instagram.png" alt="instagram"></a></li>
                    </ul> 
                </div>
            </div>
            
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Projects</h3>
                    <ul class="support-list">
                        <li *ngFor="let item of categoriesApiData | keyvalue"><a class="own_display_top" [routerLink]="['/projects',item.key.replace(' ', '-')+'-'+item.value.data.id]" >{{ item.key }}</a></li>
                    
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Company</h3>
                    <ul class="services-list">
                        <li><a routerLink="/" class="own_display_top">Home</a></li>
                        <li><a routerLink="/about" class="own_display_top">About Us</a></li>
                        <li><a routerLink="/jobs" class="own_display_top">Jobs</a></li>
                        <li><a routerLink="/team" class="own_display_top">Team</a></li>
                        <li><a routerLink="/" class="own_display_top">Contact us</a></li>
                        <!-- <li><a routerLink="/">Features</a></li>
                        <li><a routerLink="/">Our Pricing</a></li>
                        <li><a routerLink="/">Latest News</a></li> -->
                    </ul>
                </div>
            </div>
        </div>
        <div class="copyright-area">
            <p>© {{copyright}}</p>
        </div>
    </div>
</footer>

<div class="go-top"><i class='bx bx-chevron-up'></i></div>