import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeHtml  } from '@angular/platform-browser';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss']
})
export class JobsComponent {
  APP_PATH = "https://admin.rapidsai.com/";
  API_PATH = "https://admin.rapidsai.com/api/";
  jobsApiData : any = [];
  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.http.get(this.API_PATH+'jobs').subscribe((data) => {
        this.jobsApiData = data;
    });
  }
  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
  transformJobTitle(title: string): string {
    return title.replace(/ /g, '-');
  }
}
