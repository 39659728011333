
<div class="page-title-area service-title-area">
    <div id="particles-js" class="particles-js-page"></div>
    <div class="container"> 
        <div class="page-title-content">
            <h2 class="service-title">{{ title }}</h2>
        </div>
    </div>
</div>
<section class="video-presentation-area pt-100 pb-70 bg-fff">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <p [innerHTML]="sanitizeHtml(detail)"></p>
            </div>
        </div>
    </div>
</section>
<section class="video-presentation-area pt-100 pb-70 bg-white" *ngIf=" whyusApiData.length > 0 ">
    <div class="container">
        <div class="row">
            <ng-container *ngFor="let item of whyusApiData">
                <div class="col-lg-4 col-md-6 col-sm-6" *ngIf="item.type !== 'main'">
                    <div class="features-box">
                        
                        <div class="icon" [innerHTML]="sanitizeHtml(item.icon_path)" *ngIf="item.image_or_icon == 'icon'"> </div>

                        <div class="icon"  *ngIf="item.image_or_icon == 'image'"> 
                            <img src="{{ APP_PATH+item.image_path }}" class="service-img" alt="image">
                        </div>
                        <h3>{{ item.title }}</h3>
                        <p [innerHTML]="sanitizeHtml(item.detail)"></p>
                        <div class="back-icon" [innerHTML]="sanitizeHtml(item.icon_path)" *ngIf="item.image_or_icon == 'icon'"> </div>
                        <div class="back-icon" *ngIf="item.image_or_icon == 'image'">
                            <img src="{{ APP_PATH+item.image_path }}" alt="image">
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</section>




<section class="video-presentation-area pt-100 pb-70 bg-fff"  *ngIf="projectsApiData.length > 0">
    <div class="container">
        <div class="section-title">
            <h2>Our Latest Projects</h2>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6"   *ngFor="let item of projectsApiData;">
                <div class="single-blog-post wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                    <div class="post-image">
                        <a [routerLink]="['/project-detail',item.project_title.replace(' ', '-')+'-'+item.id]"><img src="{{ APP_PATH+'uploads/'+item.image_path }}" alt="image"></a>
                        <!-- <div class="date"><i class='bx bx-calendar'></i>{{ formatDate(item.created_at) }}</div> -->
                    </div>
                    <div class="post-content">
                        <h3 class="post-content-h3"><a [routerLink]="['/project-detail',item.project_title.replace(' ', '-')+'-'+item.id]">{{ item.project_title }}</a></h3>
                        <div class="post-info">
                            <!-- <div class="post-by">
                                <h6>{{ item.technologies }}</h6>
                            </div> -->
                            <div class="details-btn">
                                <a [routerLink]="['/project-detail',item.project_title.replace(' ', '-')+'-'+item.id]"><i class="bx bx-right-arrow-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
