<div class="page-title-area service-title-area">
    <div id="particles-js" class="particles-js-page"></div>
    <div class="container">
        <div class="page-title-content">
            <h2 class="service-title">{{ projectTitle }} Detail</h2>
        </div>
    </div>
</div>
<section class="video-presentation-area  bg-f8fbfa ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-image">
                        <img src="{{ APP_PATH+projectApiData.image_path }}" alt="image">
                    </div>
                    <div class="article-content">
                        <div class="entry-meta">
                            <ul>
                                <li><i class='bx bx-time'></i> <a href="#">{{ formatDate(projectApiData.created_at) }}</a></li>
                                <!-- <li><i class='bx bx-user'></i> <a href="#">{{ projectApiData.project_title }}</a></li> -->
                            </ul>
                        </div>
                        <div [innerHTML] = "sanitizeHtml(projectApiData.project_detail)"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="free-trial-area ptb-100 bg-f4f5fe">
    <div class="container">
        <div class="free-trial-content">
            <h2>We always try to be the best support to you as possible</h2>
            <p>Qualify your leads & recognize the value of word your customer will love you</p>
            <a routerLink="/contact" class="default-btn"><i class="bx bxs-hot"></i> Contact Us<span></span></a>
        </div>
    </div>
</section>