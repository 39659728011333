
<div class="page-title-area service-title-area">
    <div id="particles-js" class="particles-js-page"></div>
    <div class="container">
        <div class="page-title-content">
            <h2 class="service-title">Team</h2>
        </div>
    </div>
</div>
<section class="video-presentation-area pt-100 pb-70 bg-fff" *ngIf="topThreeTeamApiData.length > 0 ">
    <div class="container">
        <div class="section-title">
            <h2>Board of Directors</h2>
        </div>
        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6" *ngFor="let item of topThreeTeamApiData;">
                <div class="single-team-box wow animate__animated animate__fadeInUp" data-wow-delay=".6s">
                    <div class="image">
                        <a [routerLink]="['/team-detail',transformJobTitle(item.name)+'-'+item.id]">
                            <img src="{{ APP_PATH+item.image_path }}" alt="image">
                        </a>
                        <ul class="social">
                            <li><a href="{{ item.facebook_link}}" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="{{ item.twitter_link}}" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="{{ item.linkedin_link}}" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="{{ item.instagram_link}}" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <a [routerLink]="['/team-detail',transformJobTitle(item.name)+'-'+item.id]">
                            <h3>{{ item.name }}</h3>
                        </a>
                        <span>{{ item.designation }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> 
<section class="video-presentation-area pt-100 pb-70  bg-fff" *ngIf="teamApiData.length > 0 ">
    <div class="container">
        <div class="section-title">
            <h2>Meet Our Amazing Team</h2>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6" *ngFor="let item of teamApiData;">
                <div class="single-team-box">
                    <div class="image">
                        <a [routerLink]="['/team-detail',transformJobTitle(item.name)+'-'+item.id]">
                            <img src="{{ APP_PATH+item.image_path }}" alt="image">
                        </a>
                    </div> 
                    <div class="content">
                        <a [routerLink]="['/team-detail',transformJobTitle(item.name)+'-'+item.id]">
                            <h3>{{ item.name }}</h3>
                        </a>
                        <span>{{ item.designation }}</span>
                    </div>
                </div>
            </div> 
        </div>
    </div>
</section>

<section class="video-presentation-area ptb-100 bg-f4f5fe" style="position: relative;"  *ngIf="clientsApiData.length > 0 ">
    <div class="container">
        <div class="section-title">
            <h2>Our Loving Clients</h2>
        </div>
        <div class="clients-logo-list align-items-center">
            <div class="single-clients-logo wow animate__animated animate__fadeInUp" data-wow-delay=".2s"  *ngFor="let item of clientsApiData">
                <a href="#">
                    <img src="{{ APP_PATH+item.image_path }}" alt="image">
                </a>
            </div>
        </div>
    </div>
</section>