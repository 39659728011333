
<div class="page-title-area service-title-area">
    <div id="particles-js" class="particles-js-page"></div>
    <div class="container">
        <div class="page-title-content">
            <h2 class="service-title">Contact us</h2>
        </div>
    </div>
</div>
<section class="video-presentation-area ptb-100 bg-f4f6fc">
    <div class="container">
        <div class="contact-inner">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="contact-features-list">
                        <h3 [innerHTML]="title"></h3>
                        <p [innerHTML]="detail"></p>                        
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="contact-form">
                        <h3>Let's Start Your Free Trial</h3>
                        <div *ngIf="alertMessage" class="alert alert-success">{{ alertMessage }}</div>
                        <form  [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" formControlName="name" id="name" class="form-control" required placeholder="Your Name">
                                        <div *ngIf="contactForm.get('name').hasError('required') && contactForm.get('name').touched" class="error-message">
                                            Name is required.
                                        </div>
                                        <div *ngIf="contactForm.get('name').hasError('minlength') && contactForm.get('name').touched" class="error-message">
                                            Name should be at least 2 characters long.
                                        </div>
                                        <div *ngIf="contactForm.get('name').hasError('maxlength') && contactForm.get('name').touched" class="error-message">
                                            Name should be at most 50 characters long.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="email" formControlName="email" id="email" class="form-control" required placeholder="Your Email">
                                        <div *ngIf="contactForm.get('email').hasError('required') && contactForm.get('email').touched" class="error-message">
                                            Email is required.
                                        </div>
                                        <div *ngIf="contactForm.get('email').hasError('email') && contactForm.get('email').touched" class="error-message">
                                            Please enter a valid email address.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" formControlName="phone_number" id="phone_number" required class="form-control" placeholder="Your Phone">
                                        <div  class="error-message" *ngIf="contactForm.get('phone_number').hasError('required') && contactForm.get('phone_number').touched">
                                            Phone number is required.
                                        </div>
                                        <div  class="error-message" *ngIf="contactForm.get('phone_number').hasError('pattern') && contactForm.get('phone_number').touched">
                                            Please enter a valid phone number.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" formControlName="msg_subject" id="msg_subject"  class="form-control" required placeholder="Your Subject">
                                        <div class="error-message" *ngIf="contactForm.get('msg_subject').hasError('required') && contactForm.get('msg_subject').touched">
                                            Message subject is required.
                                        </div>
                                        <div class="error-message" *ngIf="contactForm.get('msg_subject').hasError('minlength') && contactForm.get('msg_subject').touched">
                                            Message subject should be at least 5 characters long.
                                        </div>
                                        <div class="error-message" *ngIf="contactForm.get('msg_subject').hasError('maxlength') && contactForm.get('msg_subject').touched">
                                            Message subject should be at most 100 characters long.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea formControlName="message" class="form-control" id="message" cols="30" rows="6" required placeholder="Your Message"></textarea>
                                        <div  class="error-message" *ngIf="contactForm.get('message').hasError('required') && contactForm.get('message').touched">
                                            Message is required.
                                        </div>
                                        <div class="error-message" *ngIf="contactForm.get('message').hasError('minlength') && contactForm.get('message').touched">
                                            Message should be at least 10 characters long.
                                        </div>
                                        <div class="error-message" *ngIf="contactForm.get('message').hasError('maxlength') && contactForm.get('message').touched">
                                            Message should be at most 500 characters long.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <button  type="submit" [disabled]="!contactForm.valid" class="default-btn"><i class='bx bxs-paper-plane'></i>Send Message<span></span></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="contact-info">
                <div class="contact-info-content">
                    <h3>Contact us by Phone Number or Email Address</h3>
                    <h2>
                        <a href="tel:+0881306298615">{{phone}}</a>
                        <span>OR</span>
                        <a  [href]="'mailto:' + email">{{email}}</a>
                    </h2>
                    <ul class="social">
                        <li><a [href]="twitter" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        <li><a [href]="fb" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a [href]="google" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                        <li><a [href]="inst" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
