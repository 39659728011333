import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;
  alertMessage: string = ''; 
  apiData : any;
  phone : string = "";
  email : string = "";
  inst : string = "";
  twitter : string = "";
  google : string = "";
  fb : string = "";
  title : any;
  detail : any;
  APP_PATH = "https://admin.rapidsai.com/";
  API_PATH = "https://admin.rapidsai.com/api/";
  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private top_title: Title) {
      this.top_title.setTitle('Contact us - RapidsAI')}
  
  ngOnInit() {
    this.contactForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      email: ['', [Validators.required, Validators.email]],
      phone_number: ['', [Validators.required, Validators.pattern('[0-9]*')]],
      msg_subject: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(100)]],
      message: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(500)]]
    });

    this.http.get(this.API_PATH+'footer').subscribe((data) => {
      this.apiData = data;
        this.phone = this.apiData.phone;
        this.email = this.apiData.email;
        this.inst = this.apiData.inst;
        this.twitter = this.apiData.twitter;
        this.google = this.apiData.google;
        this.fb = this.apiData.fb;
  });

  this.http.get(this.API_PATH+'contact_text').subscribe((data: any) => {
    this.title = data.title;
    this.detail = data.detail;
  });
  }
  onSubmit() {
    if (this.contactForm.valid) {
      // Send a POST request to the API here
      const formData = this.contactForm.value;
      this.http.post(this.APP_PATH+'Welcome/contact_request', formData, { withCredentials: true }) .subscribe((response: any) => {
        if (response.status == "sent") { // Adjust based on your actual response format
          this.contactForm.reset(); // Clear the form values
          this.alertMessage = 'Message has been sent to support. Thanks for contacting.';
        } else {
          this.alertMessage = 'Message sending failed. Please try again later.';
        }
      }, (error) => {
        console.error('Error:', error);
        this.alertMessage = 'An error occurred. Please try again later.';
      });
  } else {
    this.contactForm.markAllAsTouched();
  }
  }
}
