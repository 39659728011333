import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  APP_PATH = "https://admin.rapidsai.com/";
  API_PATH = "https://admin.rapidsai.com/api/";
  apiData : any;
  aboutus = '';
  logo = '';
  address = '';
  phone = '';
  email = '';
  copyright = '';
  inst = '';
  twitter = '';
  google = '';
  fb = '';
  categoriesApiData : any = {};

  constructor( private http: HttpClient ) { }

  ngOnInit() {
    this.http.get(this.API_PATH+'footer').subscribe((data) => {
      this.apiData = data;
        this.aboutus = this.apiData.aboutus;
        this.logo = this.APP_PATH+"uploads/"+this.apiData.logo2;
        this.address = this.apiData.address;
        this.phone = this.apiData.phone;
        this.email = this.apiData.email;
        this.copyright = this.apiData.copyright;
        this.inst = this.apiData.inst;
        this.twitter = this.apiData.twitter;
        this.google = this.apiData.google;
        this.fb = this.apiData.fb;
  });
  this.http.get(this.API_PATH+'categories').subscribe((data) => {
    this.categoriesApiData = data;
});
  }

}
