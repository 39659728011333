<div class="{{navbarClass}}">
    <div class="container-fluid pl-100">
        <nav class="navbar navbar-expand-lg navbar-light">
            <a class="navbar-brand" routerLink="/" *ngIf="logo">
                <img [src]="logo" alt="logo" style="width: 120px !important;">
            </a>

            <button class="navbar-toggler" (click)="toggleNavbar()" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>        

            <div class="collapse navbar-collapse" id="navbarSupportedContent" [ngClass]="{'show': isNavbarVisible}">
                <ul class="navbar-nav">

                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                        <a (click)="handleNavLinkClick()" routerLink="/" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home</a>
                    </li>

                    <li class="nav-item" (mouseover)="handleServicesMouseOver()" (mouseout)="handleServicesMouseOut()" >
                        <a href="#" class="nav-link">Services <i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu dynamicWidth">
                            <div class="row" >
                                <div class="col" *ngFor="let category of getCategoriesArray()">
                                    <!-- [routerLink]="['/services','parent-'+categoriesApiData[category].data.name.trim().replace(' ', '-')+'-'+categoriesApiData[category].data.id.trim()]" -->

                                    <li class="nav-item dropdown-item"  (click)="handleDropdownItemClick()" data-toggle="collapse" data-target=".navbar-collapse.show">
                                        <a  style="border-bottom: 1px solid #e5e5e5; font-weight:600;" 
                                        href="#"
                                        class="nav-link" 
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{ categoriesApiData[category].data.name }}</a>
                                    </li>
                                    <li class="nav-item dropdown-item" data-toggle="collapse" (click)="handleDropdownItemClick()" 
                                     data-target=".navbar-collapse.show"  *ngFor="let item_child of  categoriesApiData[category].child">
                                        <a (click)="handleNavLinkClick()"  [routerLink]="['/services','child-'+item_child.name.trim().replace(' ', '-')+'-'+item_child.id.trim()]" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{item_child.name}}</a>
                                    </li>
                                </div>
                            </div>
                        </ul>
                    </li>
                     
                    <li class="nav-item">
                        <a href="#" class="nav-link" >Projects <i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                          <li class=" nav-item" data-toggle="collapse" data-target=".navbar-collapse.show" *ngFor="let category of getCategoriesArray()">
                            <!-- Access the category object using category -->
                            <a (click)="handleNavLinkClick()" [routerLink]="['/projects', categoriesApiData[category].data.name.trim().replace(' ', '-') + '-' + categoriesApiData[category].data.id]" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                              {{ categoriesApiData[category].data.name }}
                            </a>
                          </li>
                        </ul>
                      </li>
                      
                      
                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                        <a (click)="handleNavLinkClick()" routerLink="/team" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Team</a>
                    </li>

                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                        <a (click)="handleNavLinkClick()" routerLink="/about" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">About</a>
                    </li>
                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                        <a (click)="handleNavLinkClick()" routerLink="/jobs" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Jobs</a>
                    </li>
                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                        <a (click)="handleNavLinkClick()" routerLink="/contact" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Contact</a>
                    </li>
                </ul>
                <div class="others-options">
                </div>
            </div>
        </nav>
    </div>
</div>
