import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';

import { HomeOneComponent } from './components/pages/home-one/home-one.component';
 import { AboutComponent } from './components/pages/about/about.component';
 import { TeamComponent } from './components/pages/team/team.component';
 import { ContactComponent } from './components/pages/contact/contact.component';
import { ErrorComponent } from './components/pages/error/error.component';
 import { ServicesOneComponent } from './components/pages/services-one/services-one.component';
import { ProjectsComponent } from './components/pages/projects/projects.component';
import { ProjectDetailComponent } from './components/pages/project-detail/project-detail.component';
import { JobsComponent } from './components/pages/jobs/jobs.component';
import { JobDetailComponent } from './components/pages/job-detail/job-detail.component';
import { TeamDetailComponent } from './components/pages/team-detail/team-detail.component';

const routes: Routes = [
    {path: '', component: HomeOneComponent},
    {path: 'about', component: AboutComponent}, 
    {path: 'jobs', component: JobsComponent}, 
    {path: 'job-detail/:id', component: JobDetailComponent},
    {path: 'team-detail/:id', component: TeamDetailComponent},
    {path: 'services/:id', component: ServicesOneComponent},
    {path: 'team', component: TeamComponent},
    {path: 'projects/:id', component: ProjectsComponent},
    {path: 'project-detail/:id', component: ProjectDetailComponent},
    {path: 'error', component: ErrorComponent},
    {path: 'contact', component: ContactComponent},
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule],
    providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],


})
export class AppRoutingModule { }
