import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {
    teamApiData : any = {
      image_path : '',
      name : '',
      designation : ''
    };
    topThreeTeamApiData : any = {
      image_path : '',
      name : '',
      designation : ''
    };
    testimonialsApiData : any = {
        "company_name" : "",
        "client_name": "",
        "testimonial_text": "",
        "image_path": "",
    };
    clientsApiData : any = {
      "image_path": "",
    };  
    APP_PATH = "https://admin.rapidsai.com/";
    API_PATH = "https://admin.rapidsai.com/api/";
    constructor(private http: HttpClient) { }

    
    ngOnInit() {
      this.get_team();
      this.get_clients();
      this.get_testimonials();
    }
    get_team(){
      this.http.get(this.API_PATH+'topThreeTeam').subscribe((data) => {
        this.topThreeTeamApiData = data;
      });
      this.http.get(this.API_PATH+'team').subscribe((data) => {
        this.teamApiData = data;
      });
    }
    get_clients(){
      this.http.get(this.API_PATH+'clients').subscribe((data) => {
        this.clientsApiData = data;
      });
    }
    get_testimonials(){
      this.http.get(this.API_PATH+'testimonials').subscribe((data) => {
        this.testimonialsApiData = data;
      });
    }
    transformJobTitle(title: string): string {
      return title.replace(/ /g, '-');
    }
}
