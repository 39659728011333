import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent { 
  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private top_title: Title,
    private datePipe: DatePipe
  ) {
      this.top_title.setTitle('Projects - RapidsAI');
    }
    APP_PATH = "https://admin.rapidsai.com/";
    API_PATH = "https://admin.rapidsai.com/api/";  
    dynamicId : string;
    categoriesApiData : any = [];
    parentTitle = '';
  ngOnInit() {
    this.route.params.subscribe(params => {
      this.dynamicId  = params['id']; // Get the 'id' parameter from the route
      const parts = this.dynamicId.split('-');
      const valuesExceptLast = parts.slice(0, -1);
      this.parentTitle =  valuesExceptLast.join(' ');

      this.get_projects_categories();
    }); 
  }

  get_projects_categories(){
    this.http.get(this.API_PATH+'projects_categories/'+this.dynamicId).subscribe((data) => {
      this.categoriesApiData = data;
    });
  }
  formatDate(dateString: string): string {
    // Parse the input date into a Date object
    const parsedDate = this.datePipe.transform(dateString, 'dd/MM/yyyy', 'MM/dd/yyyy');

    // Format the date in the desired format
    return this.datePipe.transform(parsedDate, 'MMMM dd, yyyy');
  }
}
