import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  clientsApiData  : Array<any> = []
  APP_PATH = "https://admin.rapidsai.com/";
  API_PATH = "https://admin.rapidsai.com/api/";
  why_choose_us_link ='';
  completed_projects : number = 0;
  happy_client : number = 0;
  ongoing_projects : number = 0;
  winning_award : number = 0;
  statisticApiData : any;
  aboutImages: Array<any> = []
  testimonialsApiData : Array<any> = []
  topThreeTeamApiData : Array<any> = []
  title : any;
  detail: any;
  constructor(private top_title: Title,private http: HttpClient,) {
    this.top_title.setTitle('About us - RapidsAI') }

  ngOnInit() {
    this.http.get(this.API_PATH+'about_text').subscribe((data: any) => {
      this.title = data.title;
      this.detail = data.detail;
    });
    this.http.get(this.API_PATH+'about_image').subscribe((data: any) => {
      this.aboutImages = data;
    });
    this.get_clients();
    this.get_team();
    this.get_statistics();
    this.get_testimonials();
  }
  get_clients(){
    this.http.get(this.API_PATH+'clients').subscribe((data: any) => {
      this.clientsApiData = data;
    });
  }
  get_team(){
    this.http.get(this.API_PATH+'topThreeTeam').subscribe((data: any) => {
      this.topThreeTeamApiData = data;
    });
  }
  get_statistics(){
    this.http.get(this.API_PATH+'statistics').subscribe((data) => {
      this.statisticApiData = data;
      this.completed_projects = this.statisticApiData.completed_projects;
      this.happy_client = this.statisticApiData.happy_client;
      this.ongoing_projects = this.statisticApiData.ongoing_projects;
      this.winning_award = this.statisticApiData.winning_award;
      this.why_choose_us_link = this.statisticApiData.why_choose_us_link;
    });
  }
  get_testimonials(){
    this.http.get(this.API_PATH+'testimonials').subscribe((data: any) => {
      this.testimonialsApiData = data;
    });
  }
  transformJobTitle(title: string): string {
    return title.replace(/ /g, '-');
  }
}
