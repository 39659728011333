import { Component, ElementRef, OnInit, SecurityContext } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { DomSanitizer, SafeHtml  } from '@angular/platform-browser';

@Component({
  selector: 'app-home-one',
  templateUrl: './home-one.component.html',
  styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {

  constructor(
    private http: HttpClient,
    private top_title: Title,
    private datePipe: DatePipe,
    private sanitizer: DomSanitizer, 
    private elementRef: ElementRef
    ){
    this.top_title.setTitle('Home - RapidsAI') 
  }
  APP_PATH = "https://admin.rapidsai.com/";
  API_PATH = "https://admin.rapidsai.com/api/";
  isChatOpen: boolean = false;

 
  headerApiData : any = {
      "title": "",
      "detail": "",
      "image_path": "",
  };
  headerImage : any = 'assets/img/white.webp';

  topFourApiData : any;
  serviceDetailApiData : any;
  statisticApiData : any;
  servicesApiData : any = {
    "image_path": "",
    "title": "",
    "detail": "",
};
annoucementsApiData : Array<any> = []
  testimonialsApiData : Array<any> = []
  clientsApiData : any = {
    "image_path": "",
  };
  faqsApiData : any = {
    "title": "",
    "detail": "",
};
  projectsApiData : any= {
    "title": "",
    "created_at": "",
    "project_title": "",
    "technologies": "",
};

  headerTitle: any;
  completed_projects : number = 0;
  happy_client : number = 0;
  ongoing_projects : number = 0;
  winning_award : number = 0;
  faq_image : any  = {
    "image_path": "",
  };
  why_choose_us_link: any;
  ngOnInit() {
    this.get_header();
    this.get_annoucements();
    this.get_service_detail();
    this.get_testimonials();
    this.get_statistics();
    this.get_projects();
    this.get_faqs();
   // this.get_clients();
  }

  get_header(){
    this.http.get(this.API_PATH+'header').subscribe((data) => {
      this.headerApiData = data;
      this.headerImage = this.APP_PATH+this.headerApiData.image_path;
      this.headerTitle = this.sanitizer.bypassSecurityTrustHtml(this.headerApiData.title);
      //this.get_top_four();
    });
  }
  get_annoucements(){
    this.http.get(this.API_PATH+'/annoucements').subscribe((data: any) => {
  //this.http.get('http://proj10.nl/api/annoucements').subscribe((data) => {
      this.annoucementsApiData = data;
    });
  }
   toggleChat() {
    this.isChatOpen = !this.isChatOpen;
  }
  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
  get_top_four(){
    this.http.get(this.API_PATH+'top_four_services').subscribe((data) => {
      this.topFourApiData = data;
    });
  }
  get_service_detail(){
    this.http.get(this.API_PATH+'service_detail').subscribe((data) => {
      this.serviceDetailApiData = data;
    });
  }
  get_statistics(){
    this.http.get(this.API_PATH+'statistics').subscribe((data:any) => {
      this.statisticApiData = data;
      this.completed_projects = this.statisticApiData.completed_projects;
      this.happy_client = this.statisticApiData.happy_client;
      this.ongoing_projects = this.statisticApiData.ongoing_projects;
      this.winning_award = this.statisticApiData.winning_award;
      this.why_choose_us_link = this.statisticApiData.why_choose_us_link;
      //this.get_services();
    });
  }

  get_services(){
    this.http.get(this.API_PATH+'services').subscribe((data) => {
      this.servicesApiData = data;
      
    });
  }

  get_testimonials(){
    this.http.get(this.API_PATH+'testimonials').subscribe((data: any) => {
      this.testimonialsApiData = data;
    });
  }
 
  get_clients(){
    this.http.get(this.API_PATH+'clients').subscribe((data) => {
      this.clientsApiData = data;
      
    });
  }

  get_faqs(){
    this.http.get(this.API_PATH+'faq_image').subscribe((data) => {
      this.faq_image = data;
    });
    this.http.get(this.API_PATH+'faqs').subscribe((data : any) => {
      this.faqsApiData = data;
    });

  }
  get_projects(){
    this.http.get(this.API_PATH+'projects').subscribe((data) => {
      this.projectsApiData = data;
    });
  }
  formatDate(dateString: string): string {
    // Parse the input date into a Date object
    const parsedDate = this.datePipe.transform(dateString, 'dd/MM/yyyy', 'MM/dd/yyyy');

    // Format the date in the desired format
    return this.datePipe.transform(dateString, 'MMMM dd, yyyy');
  }
  formatDate_slash(dateString: string): string {
    // Parse the input date into a Date object
    //const parsedDate = this.datePipe.transform(dateString, 'dd/MM/yyyy', 'MM/dd/yyyy');

    // Format the date in the desired format
    return this.datePipe.transform(dateString, 'MMMM dd, yyyy');
  }
}
